<template>
    <div id="rec">
        <Transition name="fade-transition">           

            <!--Recovery stepper-->
            <v-stepper v-if="!password_reset_active" v-model="rec_step" elevation="4" class="white" dark>

                <v-toolbar class="secondary white--text">
                    <v-toolbar-title>
                        Password Recovery
                    </v-toolbar-title>
                </v-toolbar>

                <!--Pre-fetch badge in the event of a direct load in here, but do not display-->
                <v-img class="mx-auto" style="display:none;"
                       max-height="225"
                       max-width="225" contain
                       src="../images/blackwhite_badge_supermin4.png" eager
                       transition="fade-transition">

                    <template v-slot:placeholder>
                        <v-row class="fill-height ma-0"
                               align="center"
                               justify="center">
                            <v-progress-circular indeterminate
                                                 color="grey lighten-5"></v-progress-circular>
                        </v-row>
                    </template>

                </v-img>

                <v-stepper-items>

                    <!--Email Step 1-->
                    <v-stepper-content step="1">

                        <v-card class="mb-12" light flat
                                height="auto">
                            <div>
                                Please enter your email address below, after which one of your security questions will appear.
                                <br />
                                <br />
                                If the question is answered correctly, a link to reset your password will be sent to your email. Clicking the link inside your email will allow you to create a new password for your account.

                                <br /><br />The arrows can be used to navigate between the recovery steps. You can cancel the recovery at any time using the "<strong>Cancel</strong>" button and return to the login page.

                            </div>

                            <v-form ref="email_step_form" v-model="email_step_form_valid" class="mt-4">
                                <v-text-field label="*Email" @keydown.enter.prevent clearable maxlength="100"
                                              placeholder="JohnDoe@reno.gov"
                                              required
                                              v-model="rec_form.email"
                                              :rules="[field_rules.required, field_rules.email]">
                                </v-text-field>

                            </v-form>

                        </v-card>

                        <v-divider light></v-divider>
                        <v-row justify="center" align="center" dense class="mt-4">
                            <v-col md="1" class="text-center">
                                <v-btn light disabled color="primary" @click="rec_step = 1" class="pa-0">
                                    <v-icon>
                                        $vuetify.icons.arrowLeft
                                    </v-icon>

                                </v-btn>
                            </v-col>

                            <v-col md="1" class="text-center">
                                <v-btn light text color="red" @click="cancel_rec" class="pa-0">
                                    Cancel
                                </v-btn>
                            </v-col>

                            <v-col md="1" class="text-center">
                                <v-btn light color="primary" @click="validateEmail()" :disabled="!email_step_form_valid" class="pa-0">
                                    <v-icon>
                                        $vuetify.icons.arrowRight
                                    </v-icon>
                                </v-btn>
                            </v-col>




                        </v-row>




                    </v-stepper-content>

                    <!--Security Question Step 2-->
                    <v-stepper-content step="2">

                        <v-card class="mb-12" light flat
                                height="auto">
                            <div>
                                Please answer the following security question : <strong>{{rec_form.security_question}}</strong>
                            </div>

                            <v-form ref="secq_step_form" v-model="secq_step_form_valid" class="mt-4">
                                <v-text-field label="*Answer" @keydown.enter.prevent clearable maxlength="200"
                                              placeholder=""
                                              required
                                              v-model="rec_form.security_question_answer"
                                              :rules="[field_rules.required]">
                                </v-text-field>

                            </v-form>

                        </v-card>

                        <v-divider light></v-divider>
                        <v-row justify="center" align="center" dense class="mt-4">
                            <v-col md="1" class="text-center">
                                <v-btn light color="primary" @click="rec_step = 1" class="pa-0" disabled>
                                    <v-icon>
                                        $vuetify.icons.arrowLeft
                                    </v-icon>

                                </v-btn>
                            </v-col>

                            <v-col md="1" class="text-center">
                                <v-btn light text color="red" @click="cancel_rec" class="pa-0">
                                    Cancel
                                </v-btn>
                            </v-col>

                            <v-col md="1" class="text-center">
                                <v-btn light color="green" @click="ValidateSecq" :disabled="!secq_step_form_valid" class="pa-0">
                                    <v-icon>
                                        $checkboxMarkedOutline
                                    </v-icon>
                                </v-btn>
                            </v-col>




                        </v-row>




                    </v-stepper-content>


                </v-stepper-items>
                <v-overlay absolute :value="rec_step_loading">
                    <v-progress-circular indeterminate
                                         color="primary"></v-progress-circular>
                </v-overlay>
            </v-stepper>

            <v-card v-else class="mx-auto"
                    max-width="800" elevation="4">

                <v-toolbar class="secondary white--text">
                    <v-toolbar-title>
                        New Password Creation
                    </v-toolbar-title>
                </v-toolbar>

                <div class="mx-5 mt-5">
                    Please create a new *required CJIS compliant password below. This password must be at least 8 characters in length and contain at least (1 Upper Case Letter) + (1 Lower Case Letter) + (1 Number) and (1 Symbol !@#$&*%^).
                    <br />
                    <br />
                    <strong>Tip:</strong> Pick two-three words that are easy to remember like "GoFishingNow". Then remove the vowels and add a number and symbol in any place like "GFshngw911!"
                </div>

                <v-card-text>


                    <v-form ref="password_reset_form" v-model="password_reset_form_valid">

                        <v-text-field label="*Password" clearable maxlength="200"
                                      placeholder="********"
                                      :type="password_show ? 'text' : 'password'"
                                      :append-icon="password_show ? '$vuetify.icons.eye' : '$vuetify.icons.eyeoff'"
                                      @click:append="password_show = !password_show"
                                      required
                                      v-model="password_reset_form.password"
                                      :rules="[field_rules.required, field_rules.min, field_rules.uppercase, field_rules.lowercase, field_rules.special, field_rules.number]">
                        </v-text-field>
                        <v-text-field label="*Confirm Password" clearable maxlength="200"
                                      placeholder="********"
                                      required
                                      :type="password_confirm_show ? 'text' : 'password'"
                                      :append-icon="password_confirm_show ? '$vuetify.icons.eye' : '$vuetify.icons.eyeoff'"
                                      @click:append="password_confirm_show = !password_confirm_show"
                                      v-model="password_reset_form.password_confirm"
                                      :rules="[field_rules.required, field_rules.min, field_rules.uppercase, field_rules.lowercase, field_rules.special, field_rules.number, field_rules.match]">
                        </v-text-field>



                    </v-form>

                    <!--<v-divider class="my-2"></v-divider>-->

                    <v-row>
                        <v-col class="text-center">
                            <v-btn light :disabled="!password_reset_form_valid" color="green" @click="SubmitPasswordReset" class="mx-auto my-3">
                                Submit
                            </v-btn>
                        </v-col>
                    </v-row>



                </v-card-text>

                <v-overlay absolute :value="password_loading">
                    <v-progress-circular indeterminate
                                         color="primary"></v-progress-circular>
                </v-overlay>
            </v-card>
        </Transition>
    </div>
</template>


<style lang="scss" scoped>
</style>

<script>
    /* eslint-disable */
    import { bus_common } from "../mixins/bus_common";
    export default {
        name: "rec",
        mixins: [bus_common],
        data() {
            return {
                //Main view var
                password_reset_active: false,


                //Rec stepper vars
                rec_form_valid: false,
                rec_form: {
                    code: 0,
                    email: "",
                    security_question: "",
                    security_question_num: 1,
                    security_question_answer: "",

                },
                rec_step: 1,
                rec_step_loading: false,
                email_step_form_valid: false,
                field_rules: {
                    required: value => !!value || 'This field is required',
                    min: v => v.length >= 8 || 'Min 8 characters',
                    match: v => v == this.password_reset_form.password || 'The passwords you entered do not match',
                    uppercase: value => {
                        const pattern = /.*[A-Z].*/
                        return pattern.test(value) || '1 uppercase letter required'
                    },
                    lowercase: value => {
                        const pattern = /.*[a-z].*/
                        return pattern.test(value) || '1 lowercase letter required'
                    },
                    special: value => {
                        const pattern = /.*[!@#$&*%^].*/
                        return pattern.test(value) || '1 special (!@#$&*%^) character required'
                    },
                    number: value => {
                        const pattern = /.*[0-9].*/
                        return pattern.test(value) || '1 number required'
                    },
                    email: value => {
                        const pattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                        return pattern.test(value) || 'Please provide a valid email address'
                    }
                },
                secq_step_form_valid: false,

                //Password resetter vars
                password_loading: false,
                password_reset_form_valid: false,
                password_show: false,
                password_confirm_show: false,
                password_reset_form: {
                    code: 0,
                    password: "",
                    password_confirm: ""
                }

            };
        },
        methods: {
            clicked: function () {
                this.$emit("clicked", this.fabtype);
            },
            close_rec() {
                //Clear any paramters
                let query = Object.assign({}, this.$route.query);
                delete query.recvcode;
                this.$router.replace({ query }).catch(() => { });
                this.$emit("cancel_rec")

            },
            cancel_rec() {
                let callback = () => { this.close_rec(); }
                this.swall_diag("Cancel Recovery?", "Are you sure you would like to cancel your login recovery? <br> <br> <b> Any progress made during the recovery will be lost. <b/>", "question", callback, "Yes", "No", null)

            },
            async validateEmail() {
                let callback = () => { this.close_rec(); }
                this.rec_step_loading = true;
                let form = { code: this.rec_form.code, email: this.rec_form.email };
                await this.$axios.post('api/recovery/GetQuestion', form)
                    .then(response => {
                        this.rec_step_loading = false;
                        this.rec_form.security_question = response.data.question;
                        this.rec_form.security_question_num = response.data.qnum;
                        this.rec_step = 2;
                    }).catch(error => {
                        if (error.response) {
                            this.rec_step_loading = false;
                            if (error.response.data.msg.includes("Your session will now refresh.")) {

                                this.$swal({
                                    title: "Error!",
                                    text: error.response.data.msg,
                                    icon: "error",
                                    confirmButtonText: "OK",
                                    confirmButtonColor: '#283593',
                                    timer: 4000,
                                    didClose: callback
                                });
                            }
                            else {
                                this.swall_it("Error!", error.response.data.msg, "error", null, null);
                            }
                        }
                    });

            },
            async ValidateSecq() {
                let callback = () => { this.close_rec(); }
                this.rec_step_loading = true;
                let form = {
                    code: this.rec_form.code,
                    answer: this.rec_form.security_question_answer,
                    qnum: this.rec_form.security_question_num
                };

                await this.$axios.post('api/recovery/CheckQuestion', form)
                    .then(response => {
                        this.rec_step_loading = false;

                        this.$swal({
                            title: "Success!",
                            text: response.data.msg,
                            icon: "success",
                            confirmButtonText: "OK",
                            confirmButtonColor: '#283593',
                            timer: 4000,
                            didClose: callback
                        });

                    }).catch(error => {
                        if (error.response) {
                            this.rec_step_loading = false;
                            if (error.response.data.msg.includes("Your session will now refresh.")) {

                                this.$swal({
                                    title: "Error!",
                                    text: error.response.data.msg,
                                    icon: "error",
                                    confirmButtonText: "OK",
                                    confirmButtonColor: '#283593',
                                    timer: 4000,
                                    didClose: callback
                                });
                            }
                            else {
                                this.swall_it("Error!", error.response.data.msg, "error", null, null);
                            }
                        }
                    });

            },
            async SubmitPasswordReset() {
                let callback = () => { this.close_rec(); }
                this.password_loading = true;
                let form = {
                    code: this.password_reset_form.code,
                    password: this.password_reset_form.password,
                    passwordagain: this.password_reset_form.password_confirm
                };

                await this.$axios.post('api/recovery/CreateNewPassword', form)
                    .then(response => {
                        this.password_loading = false;

                        this.$swal({
                            title: "Success!",
                            text: response.data.msg,
                            icon: "success",
                            confirmButtonText: "OK",
                            confirmButtonColor: '#283593',
                            timer: 4000,
                            didClose: callback
                        });

                    }).catch(error => {
                        if (error.response) {
                            this.password_loading = false;
                            if (error.response.data.msg.includes("Your session will now refresh.")) {

                                this.$swal({
                                    title: "Error!",
                                    text: error.response.data.msg,
                                    icon: "error",
                                    confirmButtonText: "OK",
                                    confirmButtonColor: '#283593',
                                    timer: 4000,
                                    didClose: callback
                                });
                            }
                            else {
                                this.swall_it("Error!", error.response.data.msg, "error", null, null);
                            }
                        }
                    });
            },
            async get_init_rec() {

                await this.$axios.get('api/recovery/init')
                    .then(response => {
                        this.rec_form.code = response.data;
                    }).catch(error => {
                        if (error.response) {
                            let callback = () => { this.close_rec(); }
                            this.swall_it("Error!", error.response.data.msg, "error", callback, null);
                        }
                    });



            },
            async step0_init() {
                this.rec_step_loading = true;
                await this.get_init_rec();
                this.rec_step_loading = false;
            },
            async passwordview_init() {
                this.password_loading = true;
                this.password_reset_active = true;
                this.password_loading = false;
            },
        },
        mounted() {
            //Check if recvcode is passed, if so activate rec view
            let recvcode = this.$route.query.recvcode;
            if (recvcode != undefined && parseInt(recvcode) != 0) {
                this.password_reset_form.code = parseInt(recvcode);
                this.passwordview_init();
            }
            else {
                this.step0_init();
            }

        }
    };
</script>
